.contact {
    color: #555;
    margin: 60px auto;
  }
  .contact label span {
    display: block;
    margin: 30px 0 10px;
  }
  .contact p {
    margin-top: 10px;
    font-size: 0.8em;
  }

.contact input{
    width: 400px;
}

.contact textarea{
    width: 400px;
    height: 200px;
}