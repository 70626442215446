.nav a.brand {
  margin-right: auto;
  text-decoration: none;
}
.nav-item a:not(:first-child) {
  color: #fff;
  text-decoration: none;
  margin-left: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
}
.nav a:not(:first-child):hover {
  background: #e4e3e3;
  color: #333;
}

.nav {
  background: #a2bfc5;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 60px;
  width: 60px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
  color: #fff;
  text-decoration: none;
  margin-left: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.nav-item > .active {
  transition-delay: 0.25s;
  color: black;
  cursor: pointer;
}
