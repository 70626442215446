.home-content {
  display: flex;
  justify-content: space-around;
}

.home-content img {
  border-radius: 25px;
  border: 2px solid white;
  box-shadow: 5px 5px 5px gray;
  min-width: 240px;
}

.home-content p {
  text-align: left;
  margin-right: 4rem;
}
.logo {
  text-align: center;
}
