@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");
/* base styles */
body {
  font-family: Kalam, sans-serif;
  margin: 0;
  font-size: 1.5em;
  color: #757575;
  overflow: overlay;
  overflow-x: hidden;
  background-color: #efefef;
}

button {
  background: #a2bfc5;
  border: 2px solid #000000;
  padding: 6px 12px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

::-webkit-scrollbar {
  display: none;
}

.section-content {
  display: flex;
  flex-flow: column;
  margin: 0;
  padding: 50px;
  min-height: 100vh;
}
